import React from "react"
import { Link } from "gatsby"
import { FiHome, FiUser, FiActivity } from "react-icons/fi";
import { IconContext } from "react-icons";


export default ({ children }) => (
  <IconContext.Provider value={{ className: 'react-icons' }}>
    <div>
      Steve Farnworth &nbsp; - &nbsp; 
      <div style={{display:"inline-block"}}><Link to="/"><FiHome /> Home</Link> &nbsp; &nbsp; </div>
      <div style={{display:"inline-block"}}><Link to="/about"><FiUser /> About</Link>  &nbsp; &nbsp; </div>
      <div style={{display:"inline-block"}}><Link to="/now"><FiActivity /> Now</Link> </div>
      {children}
    </div>
  </IconContext.Provider>
)  