import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import Container from "../components/container"
import { Link } from "gatsby"
import { IconContext } from "react-icons";
import { FiBriefcase, FiBookOpen, FiCode } from "react-icons/fi";

export default () => (
  <IconContext.Provider value={{ className: 'react-icons' }}>
  <Layout>
    <Container> 
      <Header headerText="Now" />
      <div >
        <h4>What's a "Now" page? <a href="https://nownownow.com/about" target="_blank" rel="noopener noreferrer">nownownow</a></h4>
        Basically a page outlining what I'm doing and think about at the moment, on a longer timeframe than standard social media, but shorter timeframe than <Link to="/about">about</Link>
      </div>
      <br />
      <h4><FiBriefcase /> &nbsp; Work</h4>
      <p>
        Working at <a href="https://spotify.com">Spotify UK</a>.
      </p>
      <br />
      <h4><FiBookOpen /> &nbsp; Learning</h4>
      <p>
        Learning <a href="https://reactjs.org/">React</a> + <a href="https://www.gatsbyjs.org/">Gatsby.js</a> (what powers this site) for side projects that don't need a <a href="https://rubyonrails.org/">Rails</a> monolith (which I used for most things).
      </p>
      <br />
      <h4><FiCode /> &nbsp; Coding</h4>
      <p>This site using Gatsby and React</p>
      <p>
        A couple of different SaaS apps in Rails
      </p>
      <p>
        A <a href="https://gumroad.com/l/WMPCp">mobile game starter kit</a> for the <a href="https://godotengine.org/">Godot engine</a>
      </p>
    </Container>
  </Layout>
  </IconContext.Provider>
)